<template>
  <div class="container">

    
    <b-modal
      size="lg"
      ref="edit-form"
      hide-footer
    >

    
      <b-row>
        <b-col md="5">
          <b-form-group
            :label="$t('COMMON.COLUMN')"
            label-for="attr-name">
            <b-form-select id="attr-name" v-model="local_item.group_id" :options="group_options" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="5">
          <b-form-group
            :label="$t('COMMON.VALUE')"
            label-for="attr-value">
            <b-form-select id="attr-value" v-model="local_item.prop_id" :options="property_options" />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row v-if="is_free_text">

        <b-col md="5">
          <b-form-group
            :label="$t('ATTRIBUTE.FREE_TEXT')"
            label-for="attr-free_text">
            <b-form-input id="attr-free_text" v-model="local_item.text"  />
          </b-form-group>
        </b-col>
      </b-row>

      <p class="mt-2 mb-2" style="color: red; font-style: italic;" v-if="error_text">{{ error_text }}</p>

      <RightModalSaveAndCloseButtons
        class="mr-16"
        ref="save-button"
        :spin="true"
        :text="$t('COMMON.SAVE')"
        @clicked="save_clicked()"
        @close="$refs['edit-form'].hide()"
      />

    </b-modal>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {

  name: 'MemberAttributeEditor',

  props: ['item','member_id','property_groups'],
  emits: ['created','updated'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    item: {
      deep: true,
      handler(val) {
        this.local_item = { ...this.item };
        
        if (this.local_item.property && this.local_item.property.group) {
          this.local_item.group_id = this.local_item.property.group.group_id;
        }
      }
    }
  },

  computed: {

    group_options() {

      if (!this.property_groups) {
        return [];
      }

      return this.property_groups.map((group) => {
        return {
          value: group.group_id,
          text: group.name
        };
      });
    },

    property_options() {

      if (!this.property_groups) {
        return [];
      }

      const group = this.property_groups.find((group) => { return group.group_id === this.local_item.group_id; });
      
      if (group) {
        return group.properties.map((prop) => {
          return {
            value: prop.prop_id,
            text: prop.name
          };
        });
      }

      return []
    },

    is_free_text() {
      if (!this.property_groups) {
        return false;
      }

      const group = this.property_groups.find((group) => { return group.group_id === this.local_item.group_id; });

      if (group) {
        const prop = group.properties.find((prop) => { return prop.prop_id === this.local_item.prop_id; });

        if (prop) {
          return prop.is_free_text;
        }
      }

      return false;
    },

    
    is_mobile() {
      return is_mobile();
    },


  },

  methods: {

    show() {
      this.$refs['edit-form'].show();
    },

    hide() {
      this.$refs['edit-form'].hide();
    },

    async save_clicked() {
      try {
        this.error_text = null;

        if (this.local_item && this.local_item.text && this.local_item.text.length > 32) {
          this.error_text = this.$t('ATTRIBUTE.TEXT_TOO_LONG');
          return;
        }

        if (!this.local_item.id) {
          
          const res = await axios.post(`/member_property_assign/member/${this.member_id}`, this.local_item);

          if (res.status === 201) {
            this.$emit('created', res.data);
            this.$refs['save-button'].stop();
            return;
          }
          else if (res.status === 400) {
            if (res.data.reason === 'text_too_long') {
              this.error_text = this.$t('ATTRIBUTE.TEXT_TOO_LONG');
            }
            else if (res.data.reason === 'already_defined') {
              this.error_text = this.$t('ATTRIBUTE.ALREADY_DEFINED');
            }
          }
        }
        else {
          const res = await axios.put(`/member_property_assign/${this.local_item.id}`, this.local_item);

          if (res.status === 200) {
            this.local_item = { ...res.data };
            this.local_item.group_id = this.local_item.property.group.group_id;

            this.local_item = { ...this.local_item };

            this.$emit('updated', this.local_item);
            this.$refs['save-button'].stop();
            return;
          }
          else if (res.status === 400) {
            if (res.data.reason === 'text_too_long') {
              this.error_text = this.$t('ATTRIBUTE.TEXT_TOO_LONG');
            }
            else if (res.data.reason === 'already_defined') {
              this.error_text = this.$t('ATTRIBUTE.ALREADY_DEFINED');
            }
          }

          this.$refs['save-button'].stop();
        }
      }
      catch (err) {
        console.error(err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ATTRIBUTE.UNABLE_SAVE'));

      this.$refs['save-button'].stop();
    },

  },

  mounted() {

    if (this.item) {
      this.local_item = { ...this.item };
      
      if (this.local_item.property && this.local_item.property.group) {
        this.local_item.group_id = this.local_item.property.group.group_id;
      }
      
    }
    

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {
      local_item: {},
      error_text: null
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
