<!--

  Quick solution for files

-->
<template>
  <div class="container">

    
    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
        @click.prevent="selectFile"
        ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('FILE.UPLOAD_FILE')}}</a
      >
      <input hidden type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
    
    </div>


    <b-table
      id="advance-file-table"
      :fields="fields"
      :items="items"
      head-variant="light"
      sticky-header
      class="mh-100 table-striped"
    >
      <template #cell(created_at)='row'>
        <div style='min-width: 150px'> {{row.item.created_at}} </div>
      </template>
      <template #cell(file_id)="row">
        <div class='justify-content-end d-flex'>
          <a
            href="#"
            class="btn btn-icon btn-light btn-sm mx-3"
            @click.prevent="download_file_clicked(row.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <!--begin::Svg Icon-->
              <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
              <!--end::Svg Icon-->
            </span>
          </a>

          <a
            href="#"
            class="btn btn-icon btn-light btn-sm"
            @click.prevent="delete_file_clicked(row.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <!--begin::Svg Icon-->
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              <!--end::Svg Icon-->
            </span>
          </a>
        </div>
      </template>
    </b-table>
    <p v-if="items.length === 0">{{ $t('FILE.NO_FILES') }}</p>
      <!--end::Table-->
  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { upload_file } from '@/core/services/fileUpload';

import { mapGetters } from 'vuex';

export default {

  name: 'MemberFilesView',

  props: ['member','items','company','creating_member'],

  mixins: [ toasts ],

  components: {

  },

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),

  },

  methods: {
    async handleFileUpload() {
      const file = this.$refs.file.files[0];

      if (file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      try {
        const newfile = await upload_file(file, { member_id: this.member.member_id, is_member: true, file_type: 'MEMBER_PROFILE' });

        this.$emit('created', newfile);

        this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.UPLOADED'));
      }
      catch (err) {
        console.error('handleFileUpload', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_UPLOAD'));
      }
      
    },

    selectFile() {
      this.$refs.file.click();
    },
    
    download_file_clicked(file) {
      downloadWithAxios(get_base_url() + `/dlfile/${file.linkstr}`, file.name);
    },

    async delete_file_clicked(file) {

      try {
        const res = await axios.delete(`/file/${file.file_id}?company_id=${this.currentCompanyId}`);

        if (res.status === 204) {
          this.$emit('deleted', file.file_id);

          return;
        }

      }
      catch (err) {
        console.error('delete_file_clicked error', err);
      }

      this.toastr('success', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_DELETE'));
    },

  },

  mounted() {

  },

  data() {
    return {

      creating: true,

      selected_item: {},

      fields: [
        {
          key: 'name',
          label: this.$t('FILE.FILENAME'),
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7',
        },
        {
          key: 'created_at',
          label: this.$t('FILE.CREATED_AT'),
          sortable: true,
          thClass: 'w-150px',
        },
        {
          key: 'file_id',
          label: '',
          thClass: 'w-150px text-right pr-22',
        },
      ],

    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
