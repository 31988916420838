<template>
  <div>
    <!-- Table -->
    <b-row>
      <b-col cols="12">
        <div class="table-container">
          <b-table
            id="shop-order-table"
            style="width: 100%; table-layout: fixed;"
            class="table-striped"
            :fields="fields"
            :items="items"
            head-variant="light"
            ref="shop-order-table"
          >

          <template #cell(empty)="data">

            <div class="d-flex justify-content-end">
              
              <a
                class="btn btn-icon btn-light btn-sm mx-2"
                @click.prevent="edit_shop_order_clicked(data.item)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                </span>
              </a>

              <a
                class="btn btn-icon btn-light btn-sm mx-2"
                @click.prevent="delete_shop_order_clicked(data.item)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                </span>
              </a>
            </div>
          </template>

          </b-table>

          <p v-if="items.length === 0">{{$t('SHOP_ORDER.NONE_FOUND')}}</p>
        </div>
      </b-col>
    </b-row>
  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';

export default {

  name: 'MemberShopOrderTable',

  props: ['member','items'],
  emits: ['selected','delete'],

  mixins: [ toasts ],

  components: {

  },

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {

    edit_shop_order_clicked(shop_order) {
      this.$nextTick(() => {
        this.$emit('selected', shop_order);
      });
    },

    delete_shop_order_clicked(shop_order) {
      this.$nextTick(() => {
        this.$emit('delete', shop_order);
      });
    },



  },

  mounted() {

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {

      fields: [

        {
          key: 'payment_date',
          label: this.$t('SHOP_ORDER.PAID_AT'),
          sortable: false,
          mobile: false,
        },
        {
          key: 'type',
          label: this.$t('SHOP_ORDER.TYPE'),
          sortable: false,
          mobile: true,
          formatter: (_, __, item) => {

            if (item.type) {
              //return this.$t(`SHOP_ORDER.TYPES.TICKET`)
              return this.$t(`SHOP_ORDER.TYPES.${item.type}`);
            }

            return '-';
          }

        },
        {
          key: 'is_paid',
          label: this.$t('MEMBERSHIP.IS_PAID'),
          sortable: false,
          mobile: true,
          formatter: (_, __, item) => {
            return item.is_paid ? this.$t('COMMON.YES') : this.$t('COMMON.NO');
          }
        },
        {
          key: 'payment_method',
          label: this.$t('SHOP_ORDER.PAYMENT_METHOD'),
          sortable: false,
          mobile: true,
          formatter: (_, __, item) => {
            return item.payment_method.toUpperCase()
          }
        },
        {
          key: 'empty',
          label: '',
          thClass: ''
        }
      ],

      show_form: true,

    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
