<!--

  Legacy solution for invoices

-->
<template>
  <div class="container">

    <b-modal
      :title="creating ? $t('INVOICE.CREATING') : $t('INVOICE.EDITING')"
      ref="edit-form"
      hide-footer
      size="lg"
      >

      <!-- Edit form -->
      <MemberInvoiceForm
        :member="member"
        :item="selected_item"
        :creating="creating"
        @cancel="() => { this.$refs['edit-form'].hide(); }"
        @created="invoice_created"
        @updated="invoice_updated"
      />


    </b-modal>

    <MemberInvoiceTable
      :member="member"
      :items="items"

      @on_regenerate_invoice="on_invoice_regenerated"
      @updated="on_invoice_updated"
      @create="create_invoice_clicked"
      @deleted="invoice_deleted"
    />
  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';

import MemberInvoiceTable from '@/view/components/member_editor_v2/sections/member_invoice/MemberInvoiceTable.vue';
import MemberInvoiceForm from '@/view/components/member_editor_v2/sections/member_invoice/MemberInvoiceForm.vue';
import Confirm from '@/view/components/Confirm.vue';

export default {

  name: 'MemberInvoicesView',

  props: ['member','items','company','creating_member'],
  emits: ['created', 'updated', 'deleted', 'regenerated'],
  mixins: [ toasts ],

  components: {
    Confirm,
    MemberInvoiceTable,
    MemberInvoiceForm
  },

  watch: {
    items() {
      console.log('items changed')
    }
  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId','currentPeriodId']),

  },

  methods: {


    invoice_created(invoice) {
      this.$emit('created', invoice);

      this.$refs['edit-form'].hide();

      this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('INVOICE.CREATED'));
    },

    invoice_updated(invoice) {
      this.$emit('updated', invoice);

      this.$refs['edit-form'].hide();

      this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('INVOICE.UPDATED'));
    },

    invoice_deleted(invoice) {
      this.$emit('deleted', invoice);
    },

    create_invoice_clicked() {
      this.selected_item = {
        period_id: this.currentPeriodId,
        due_date: dayjs().add(30, 'day').format('YYYY-MM-DD')
      };
      this.creating = true;

      this.$nextTick(()=>{
        this.$refs['edit-form'].show();
      });
    },

    // when updated from the table, legacy
    on_invoice_updated(invoice_id, sum, mark_membership_paid) {
      this.$emit('updated', invoice_id);
    },

    // when invoice is to be regenerated, dangerout
    on_invoice_regenerated(data) {
      this.$emit('regenerated');
    },

    download_file_clicked(file) {
      downloadWithAxios(get_base_url() + `/dlfile/${file.linkstr}`, file.name);
    },

    async delete_file_clicked(file) {
      try {
        const res = await axios.delete(`/file/${file.file_id}?company_id=${this.currentCompanyId}`);

        if (res.status === 204) {
          this.files = this.files.filter(item => item.file_id !== file_id);

          this.creating = false;
          this.editing = false;

          this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.DELETED'));

          return;
        }

      }
      catch (err) {
        console.error(err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_DELETE'));

    }
  },

  mounted() {

  },

  data() {
    return {

      item_to_delete: null,

      creating: true,

      selected_item: {},

    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>

