<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <ErrorPopupHeaderMessage
        v-if="show_error_popup"
        :header="$t('COMMON.ERROR')"
        :message="$t('MEMBER_RENEW.ALREADY_HAVE_PERIOD', { period: to_period_name })"
      />

      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{ $t('MEMBER_RENEW.DETAILS') }}</v-tab>
        <v-tab>{{ $t('MEMBER_RENEW.SENDOUTS') }}</v-tab>

        <v-tab-item>

          <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('MEMBER_RENEW.STATUS')">

                    <b-form-select

                      v-model="local_item.status"
                      :options="status_options"
                      :state="validate_state({ dirty: v$['local_item']['status'].$dirty, error: v$['local_item']['status'].$error })"
                      ></b-form-select>

                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="local_item.status === 'SCHEDULED'">
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('MEMBER_RENEW.SCHEDULED_AT')">

                  <memlist-date-picker
                    v-model="local_item.scheduled_at"
                  >
                  </memlist-date-picker>

                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('MEMBER_RENEW.RENEWAL_TYPE')">

                    <b-form-select
                      v-model="local_item.renewal_type"
                      :options="renew_type_options"
                      :state="validate_state({ dirty: v$['local_item']['renewal_type'].$dirty, error: v$['local_item']['renewal_type'].$error })"
                      ></b-form-select>

                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('MEMBER_RENEW.TO_PERIOD')">

                    <b-form-select
                      @change="on_to_period_change"
                      v-model="local_item.to_period_id"
                      :options="period_options"
                      :state="validate_state({ dirty: v$['local_item']['to_period_id'].$dirty, error: v$['local_item']['to_period_id'].$error })"
                    ></b-form-select>

                </b-form-group>
              </b-col>
            </b-row>


            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('MEMBER_RENEW.EMAIL_OPTION')">

                    <b-form-select
                      v-model="local_item.email_option"
                      :options="send_options"
                      :state="validate_state({ dirty: v$['local_item']['email_option'].$dirty, error: v$['local_item']['email_option'].$error })"
                    ></b-form-select>

                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('MEMBER_RENEW.SMS_OPTION')">

                    <b-form-select
                      v-model="local_item.sms_option"
                      :options="send_options"
                      :state="validate_state({ dirty: v$['local_item']['sms_option'].$dirty, error: v$['local_item']['sms_option'].$error })"
                    ></b-form-select>

                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <required-fields-info/>
              </b-col>
            </b-row>

            <p style="margin-top: 42px;"></p>

            <p v-if="renew_short_link">{{ $t('MEMBER_RENEW.SHORT_LINK') }}: <a :href="renew_short_link">{{ renew_short_link }}</a></p>

            <p style="color: red;" v-if="show_error == 'UNPAID_MEMBERSHIPS'">
              {{ $t('MEMBER_RENEW.UNPAID_MEMBERSHIPS') }}
            </p>
            <p style="color: red;" v-if="show_error == 'INFINITE_NOT_PERIOD_DAYS'">
              {{ $t('MEMBER_RENEW.INFINITE_NOT_PERIOD_DAYS') }}
            </p>


            <RightModalSaveAndCloseButtons
              class="mr-16"
              ref="create-button"
              :spin="true"
              :text="$t('COMMON.SAVE')"
              @clicked="create_submit()"
              @close="$emit('cancel')"
            />
          </b-form>

        </v-tab-item>
        <v-tab-item>

          <MemberRenewSendoutsTable
            class="mt-8"
            :items="local_item.sendouts"
          />

        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>


<script>
import { useVuelidate } from '@vuelidate/core'
import { minLength, required, email, requiredIf } from '@vuelidate/validators';

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import ErrorPopupHeaderMessage from '@/view/components/ErrorPopupHeaderMessage.vue';

import MemberRenewSendoutsTable from '@/view/components/member_editor_v2/sections/member_renew/MemberRenewSendoutsTable.vue';

export default {

  name: 'MemberRenewEditor',

  props: ['member','item','creating','member_renewals'],
  emits: ['created', 'updated', 'cancel'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    ErrorPopupHeaderMessage,
    MemberRenewSendoutsTable
  },

  // --- begin vuelidate --- //
  setup () {
    return { v$: useVuelidate() }
  },

  validations () {
    return {
      local_item: {
        status: { required },
        renewal_type: { required },
        period_id: { required },
        to_period_id: { required },
        sms_option: { required },
        email_option: { required }
      }
    }
  },

  // -- end vuelidate -- //

  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    },

    'local_item.id'() {
      this.calc_renew_short_link(this.local_item);
    }
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings', 'sid']),

    creating() {
      return !(this.local_item && this.local_item.id);
    },

    to_period_name() {
      const period = this.periods.find(item => item.id === this.local_item.to_period_id);
      return period ? period.name : '';
    },

  },


  mounted() {

    if (this.item) {
      this.local_item = { ...this.item };
    }
    else {
      this.local_item = {}
    }

    this.company_options = this.companies.map((item) => ({ text: item.name, value: item.company_id }));
    this.period_options = this.periods.map((item) => ({ text: item.name, value: item.id }));

    this.load_settings();

    this.load_templates();

    this.calc_renew_short_link(this.local_item);

  },

  methods: {

    calc_renew_short_link(renew) {
      if (!renew) {
        return;
      }

      if (!renew.linkstr) {
        return;
      }

      this.renew_short_link = renew.is_confirm ? `${window.location.origin}/x/${renew.confirmlinkstr}` : `${window.location.origin}/x/${renew.linkstr}`;

      return this.renew_short_link;
    },

    create_submit() {
      this.on_submit();
    },

    async load_memberships() {
      try {
        const res = await axios.get(`/member_company/member/${this.member.member_id}`);

        if (res.status === 200) {
          this.memberships = res.data;
          return;
        }
      }
      catch (err) {
        console.error('load_memberships', err);
      }
    },

    async on_to_period_change() {

      this.show_error_popup = false;

      const period = this.periods.find(item => item.id === this.local_item.to_period_id);

      if (period && !period.is_infinite) {
        await this.load_memberships();

        // check if to_period already has an active renewal

        const active = this.member_renews.find(item => item.to_period_id === next_period.id && this.active_status(item.status));

        if (active) {
          this.show_error_popup = true;
        }

        this.local_item = { ...this.local_item };

      }
    },

    active_status(status) {
      switch (status) {
        case 'SCHEDULED':
        case 'ACTIVE':
        case 'STARTED_LINK':
        case 'INVOICED':
        case 'AWAITING_PAYMENT':
          return true;
      }

      return false;
    },

    validate_state(state) {
      return state.dirty ? !state.error : null;
    },


    async on_submit() {
      const valid = await this.v$.$validate();

      // always not valid, why??
      // skip validation

      /*if (valid) {
        if (this.creating) {
          await this.create_member_renew();
        }
        else {
          await this.update_member_renew();
        }
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.FORM_VALIDATION_FAILED'));
      }*/

      if (this.creating) {
        await this.create_member_renew();
      }
      else {
        await this.update_member_renew();
      }

      this.$refs['create-button'].stop();
    },

    async load_settings() {
      try {
        const res = await axios.get(`/company/admin?company_id=${this.sid}`);

        if (res.status === 200 && res.data.renew) {
          this.settings = res.data.renew;
          this.email_template_id = this.settings.email_template_id;
          this.sms_template_id = this.settings.sms_template_id;
          return;
        }

      }
      catch (err) {
        console.error('load_settings', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_GET'));
    },

    async load_templates() {
      try {
        const res = await axios.get(`/template?company_id=${this.currentCompanyId}&light=true`);

        if (res.status === 200) {

          const templates = res.data;

          this.template_sms_options = [];
          this.template_email_options = [];

          for (const t of templates) {
            if (t.is_sms) {
              this.template_sms_options.push({ value: t.template_id, text: t.name });
            }
            else {
              this.template_email_options.push({ value: t.template_id, text: t.name });
            }
          }

          return;
        }
      }
      catch (err) {
        console.error('load_templates', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
    },

    async create_member_renew() {
      try {

        const res = await axios.post(`/member_renew/${this.member.member_id}`,
          {
            company_id:                   this.currentCompanyId,
            status:                       this.local_item.status,
            scheduled_at:                 this.local_item.scheduled_at,
            period_id:                    this.currentPeriodId,
            to_period_id:                 this.local_item.to_period_id,
            email_option:                 this.local_item.email_option,
            sms_option:                   this.local_item.sms_option,
            email_template_id:            this.local_item.email_template_id,
            sms_template_id:              this.local_item.sms_template_id,
            email_reminder_template_id:   this.local_item.email_reminder_template_id,
            sms_reminder_template_id:     this.local_item.sms_reminder_template_id,
            renewal_type:                 this.local_item.renewal_type
          });

        if (res.status === 409) {
          if (res.data.reason === 'unpaid_memberships') {
            this.show_error = 'UNPAID_MEMBERSHIPS';
            return;
          }
          if (res.data.reason === 'infinite_not_period_days') {
            this.show_error = 'INFINITE_NOT_PERIOD_DAYS';
            return;
          }
        }

        if (res.status === 201) {
          this.$emit('created', res.data);
          return;
        }
      }
      catch (err) {
        console.error(err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER_RENEW.UNABLE_CREATE'));
    },

    async update_member_renew() {
      try {

        const res = await axios.put(`/member_renew/${this.local_item.id}`, this.local_item);

        if (res.status === 200) {
          this.$emit('updated', res.data);
          return;
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBERSHIP.UNABLE_UPDATE'));
      }
    },


    getPaymentStatus(status) {
      return {
        WAIT: this.$t('COMMON.WAITING'),
        PAID: this.$t('COMMON.PAID'),
        LATE: this.$t('COMMON.LATE')
      }[status];
    },

    getStatus(status) {
      return {
        SCHEDULED:        this.$t('MEMBER_RENEW.STATUSES.SCHEDULED'),
        ACTIVE:           this.$t('MEMBER_RENEW.STATUSES.ACTIVE'),
        STARTED_LINK:     this.$t('MEMBER_RENEW.STATUSES.STARTED_LINK'),
        INVOICED:         this.$t('MEMBER_RENEW.STATUSES.INVOICED'),
        DELETED:          this.$t('MEMBER_RENEW.STATUSES.DELETED'),
        DONE:             this.$t('MEMBER_RENEW.STATUSES.DONE'),
        AWAITING_PAYMENT: this.$t('MEMBER_RENEW.STATUSES.AWAITING_PAYMENT'),
        TIME_OUT:         this.$t('MEMBER_RENEW.STATUSES.TIME_OUT')
      }[status];
    },
    calcRenewTypeStr(type) {
      switch (type) {
        case 'AUTOMATIC': return this.$t('MEMBER_RENEW.RENEW_TYPES.AUTOMATIC');
        case 'MANUAL_SINGLE': return this.$t('MEMBER_RENEW.RENEW_TYPES.MANUAL_SINGLE');;
        case 'MANUAL_BATCH': return this.$t('MEMBER_RENEW.RENEW_TYPES.MANUAL_BATCH');;
      }

      return 'Okänt';
    },
    calcMethodStr(method) {
      switch (method) {
        case 'EMAIL': return this.$t('MEMBER.EMAIL');
        case 'SMS': return 'SMS';
        case 'POST': return this.$t('COMMON.BYPOST');
      }
      return 'Okänt';
    },


  },


  data() {
    return {

      show_error_popup: false,

      local_item: {},

      renew_short_link: null,

      show_error: null,

      member_renews: [],

      memberships: [],

      send_options: [
        { value: 'DO_SEND', text: this.$t('MEMBER_RENEW.DO_SEND') },
        { value: 'DONT_SEND', text: this.$t('MEMBER_RENEW.DONT_SEND') },
        { value: 'TRY_SEND', text: this.$t('MEMBER_RENEW.TRY_SEND') },
        { value: 'SEND_FAIL', text: this.$t('MEMBER_RENEW.SEND_FAIL') },
      ],

      status_options: [
        { value: 'SCHEDULED', text: this.$t('MEMBER_RENEW.STATUSES.SCHEDULED') },
        { value: 'ACTIVE', text: this.$t('MEMBER_RENEW.STATUSES.ACTIVE') },
        { value: 'STARTED_LINK', text: this.$t('MEMBER_RENEW.STATUSES.STARTED_LINK') },
        { value: 'INVOICED', text: this.$t('MEMBER_RENEW.STATUSES.INVOICED') },
        { value: 'TIME_OUT', text: this.$t('MEMBER_RENEW.STATUSES.TIME_OUT') },
        { value: 'AWAITING_PAYMENT', text: this.$t('MEMBER_RENEW.STATUSES.AWAITING_PAYMENT') },
        { value: 'DONE', text: this.$t('MEMBER_RENEW.STATUSES.DONE') },
        { value: 'DELETED', text: this.$t('MEMBER_RENEW.STATUSES.DELETED') },
      ],

      renew_type_options: [
        { value: 'RENEWAL', text: this.$t('MEMBER_RENEW.IS_RENEWAL') },
        { value: 'CONFIRM', text: this.$t('MEMBER_RENEW.IS_CONFIRM') },
      ],

      paying_options: [
        { value: true, text: this.$t('COMMON.PAID') },
        { value: false, text: this.$t('COMMON.NOT_PAID') }
      ],

      company_options: [],

      form: {},

      period_options: [],

      shop_items_options: [],

      selectedRowsMemberCompanyId: [],

      show_form: true,

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
