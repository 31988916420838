<!--

  View for Family components.

  The family component is old legacy code that must be refactored
  to the separated Form an Table components.

-->
<template>
  <div class="container">

    <!--<b-modal
      :title="creating ? $t('MEMBERSHIP.CREATING') : $t('MEMBERSHIP.EDITING')"
      ref="edit-form"
      hide-footer
      >

      <MemberCompanyForm
        :member="member"
        :item="selected_item"
        :creating="creating"
        @cancel="() => { this.$refs['edit-form'].hide(); }"
        @create="member_company_create"
        @update="member_company_update"
      />


    </b-modal>-->

    <!-- Table -->
    <div v-if="creating_member">
      {{ $t('MEMBER.CREATE_BEFORE_EDIT') }}
    </div>
    <FamilyTable
      v-else="!creating_member"
      :me="member"
      :settings="familySettings"
      :member="member"
      @on_family_data_changed="on_family_data_changed"
    />

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import FamilyTable from '@/view/components/member_editor_v2/sections/family/FamilyTable.vue';

export default {

  name: 'MemberFamilyView',

  props: ['member','items','company','creating_member'],
  emits: ['on_family_data_changed'],

  mixins: [ toasts ],

  components: {
    FamilyTable,
  },

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },

    familySettings() {
      return {
        /*personnr: this.constreq.includes('MEMBER_REQ_SSN'),
        address: this.constreq.includes('MEMBER_REQ_ADDRESS'),
        zipcode: this.constreq.includes('MEMBER_REQ_ADDRESS'),
        phone: this.constreq.includes('MEMBER_REQ_PHONE')*/
        personnr: false,
        address: false,
        zipcode: false,
        phone: false
      };
    },

  },

  methods: {

    async on_family_data_changed() {
      this.$emit('on_family_data_changed')
    },

  },

  mounted() {

  },

  data() {
    return {

      creating: true,

      selected_item: {},

    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
