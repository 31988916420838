<template>
  <div class="container">
    <MemberPasswordUpdate
      ref="passwordModal"
      :isProfilePage="true"
      :member_id="null"
    />


    <b-row>
      <b-col sm="12" md="12" lg="12" xl="8">
        <PersonalDetailsForm
          ref="personal-details"
          :member="member"
          :requirements="requirements"
          :unlocks="unlocks"

          @update="update_member"
          @change="change_member"

          @validated="member_validated"
          @create="create_member"
          @save_from_spar="save_from_spar"

        />
      </b-col>

    </b-row>


  </div>

</template>

<style lang="css" scoped>
.top-row {
  margin-bottom: 16px;
}

.icon-container {
  width: 50px;
}

.container {
  padding-bottom: 48px;
  flex: 1 0 auto;
}

.form-group {
  margin-bottom: 0px !important;
}


</style>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import MemberPasswordUpdate from '@/view/components/member_editor_v2/MemberPasswordUpdate.vue';
import PersonalDetailsForm from '@/view/components/member_editor_v2/forms/PersonalDetailsForm.vue';

export default {

  name: 'MemberEditorDetails',

  props: ['member','requirements','unlocks'],
  emits: ['update', 'change', 'create', 'validated'],
  mixins: [ toasts ],

  components: {
    MemberPasswordUpdate,
    PersonalDetailsForm
  },

  watch: {

  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'companies', 'periods', 'profile', 'settings','isTHS']),
  },

  methods: {

    force_validate() {
      this.$refs['personal-details'].force_validate();
    },

    member_validated(valid) {
      this.$emit('validated', valid);
    },

    open_password_modal() {
      this.$refs['passwordModal'].show();
    },

    update_member(member) {
      this.$emit('update', member);
    },

    change_member(member) {
      this.$emit('change', member);
    },

    create_member(member) {
      this.$emit('create', member);
    },

    save_from_spar(member) {
      this.$emit('save_from_spar', member);
    },

  },

  mounted() {
    this.show_form = this.expanded;
  },

  data() {
    return {

      show_form: true,

      gender_options: [
        { text: this.$t('COMMON.MAN'), value: 'M' },
        { text: this.$t('COMMON.WOMAN'), value: 'F' },
        { text: this.$t('COMMON.OTHER'), value: 'O' },
        { text: this.$t('COMMON.UNKNOWN'), value: 'U' },
      ],

    };
  }
};

</script>
