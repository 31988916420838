<template>
  <div class="container">

    <b-modal
      size="lg"
      ref="edit-form"
      hide-footer
    >

      <b-row>
        <b-col cols="12">

          <p v-if="local_item.swish_request_id">{{ $t('SHOP_ORDER.REFUND_SWISH') }}</p>
          <p v-if="local_item.stripe_pi_id">{{ $t('SHOP_ORDER.REFUND_STRIPE') }}</p>

          <b-form-group
            label-size="sm"
            label-cols="4"
            content-cols="6"
            :label="$t('SHOP_ORDER.REFUND_AMOUNT')">
            <b-form-input
              v-model="local_item.amount"
              :disabled="local_item.swish_refund_id === null"
            ></b-form-input>
              
          </b-form-group>

          <b-form-group
            label-size="sm"
            label-cols="4"
            content-cols="6"
            :label="$t('SHOP_ORDER.REFUND_REASON')">
            <b-form-input
              v-model="local_item.refund_reason"
              :disabled="local_item.swish_refund_id === null"
            ></b-form-input>
              
          </b-form-group>

          <b-form-group
            label-size="sm"
            label-cols="4"
            content-cols="6"
            :label="$t('SHOP_ORDER.REFUND_AT')">
            <b-form-input
              v-model="local_item.refund_at"
              :disabled="true"
            ></b-form-input>
              
          </b-form-group>
        </b-col>
      </b-row>

      <RightModalSaveAndCloseButtons
        class="mr-16"
        ref="create-button"
        :spin="true"
        :text="$t('COMMON.CREATE')"
        @clicked="create_submit()"
        @close="$refs['edit-form'].hide()"
      />

    </b-modal>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {

  name: 'MemberShopOrderRefundModal',

  props: ['shop_order'],
  emits: ['created'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {

  },

  computed: {
    
    is_mobile() {
      return is_mobile();
    },


  },

  methods: {

    show() {

      this.local_item = {
        amount: this.shop_order.refund_amount / 100 || 0,
        refund_reason: this.shop_order.refund_reason || ''
      };

      this.$refs['edit-form'].show();
    },

    hide() {
      this.$refs['edit-form'].hide();
    },

    async create_submit() {
      try {

        if (this.local_item.amount <= 0 || this.local_item.amount > this.shop_order.amount) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.REFUND_AMOUNT_ERROR'));
          this.$refs['create-button'].stop();
          return;
        }

        const res = await axios.post(`/shop_order/refund/${this.shop_order.shop_order_id}`, this.local_item);

        if (res.status === 201) {
          this.$emit('created', res.data);
          this.$refs['create-button'].stop();
          return;
        }
      }
      catch (err) {
        console.error(err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.REFUND_FAILED'));

      this.$refs['create-button'].stop();
    },

  },

  mounted() {

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {
      local_item: {}
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
