<template>
  <div class="search-container">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      class="drawer"
      left
      style="width: 300px;"
    >
      <v-list-item 
        class="px-2 drawer-header"
        >
        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>

        <v-list-item-avatar>
          <v-icon>mdi-magnify</v-icon>
        </v-list-item-avatar>

        <v-list-item-title>
          <b-form-input></b-form-input>  
        </v-list-item-title>

        
      </v-list-item>

      <v-divider style="padding: 0px !important; margin: 0px;"></v-divider>

      <v-list dense>
        <v-list-item-group
          v-model="selected_item"
          active-class="selected-item-active"
          color="indigo"
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
          >


            <v-list-item-content>
              <v-list-item-title class="fixed-font">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>
      <b-pagination
          class="ml-auto"
          v-model="current_page"
          :total-rows="items.length"
          :per-page="per_page"
          aria-controls="search-result-table"
        ></b-pagination>
    </v-navigation-drawer>
    
  </div>
  
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {

  name: 'NarrowSearchBar',

  props: ['member'],

  mixins: [ toasts ],

  components: {

  },

  watch: {
  
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'companies', 'periods', 'profile', 'settings','isTHS']),
  },

  methods: {

  },

  mounted() {
    this.show_form = this.expanded;
  },

  data() {
    return {

      mini: false,
      switch_pos: false,

      current_page: 1,
      per_page: 1,

      selected_item: 1,
      items: [
        { title: '100022, Michael Nilsson', icon: 'mdi-account' },
        { title: '100023, Sven Svensson', icon: 'mdi-account' },
        { title: '100024, Ander Andersson', icon: 'mdi-account' },
        { title: '100025, Michael Nilsson', icon: 'mdi-account' },
        { title: '100026, Sven Svensson', icon: 'mdi-account' },
        { title: '100027, Ander Andersson', icon: 'mdi-account' },
        { title: '100028, Michael Nilsson', icon: 'mdi-account' },
        { title: '100029, Sven Svensson', icon: 'mdi-account' },
        { title: '100030, Ander Andersson', icon: 'mdi-account' },
        { title: '100031, Michael Nilsson', icon: 'mdi-account' },
        { title: '100032, Sven Svensson', icon: 'mdi-account' },
        { title: '100033, Ander Andersson', icon: 'mdi-account' },
        { title: '100034, Michael Nilsson', icon: 'mdi-account' },
        { title: '100035, Sven Svensson', icon: 'mdi-account' },
      ],

      show_form: true,

    };
  }
};

</script>
