<template>
  <div>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
        @click.prevent="$emit('create')"
        ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('MEMBER_RENEW.CREATE_RENEW')}}</a
      >

    </div>
    <div class="table-container">
      <b-table
        class="table-striped"
        :fields="fields"
        :items="member_renews"
        head-variant="light"
        ref="member-table"
      >
        <template #cell(empty)="data">

          <div class="text-right">
            <a
              class="btn btn-icon btn-light btn-sm mx-2"
              @click.prevent="edit_renew_clicked(data.item.id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>

            <a
              class="btn btn-icon btn-light btn-sm mx-2"
              @click.prevent="delete_renew_clicked(data.item.id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>

      <p class="ml-4" v-if="member_renews.length === 0">{{ $t('MEMBER_RENEW.NO_RENEWS') }}</p>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Confirm from '@/view/components/Confirm.vue';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'MemberRenewTable',
  components: {
    Confirm
  },
  mixins: [ toasts ],
  props: ['member'],
  emits: ['select', 'create'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'sid'])
  },
  data() {
    return {
      selectedRenew: null,
      next_period: null,
      member_renews: [],
      fields: [
        {
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
        },
        {
          key: 'source',
          label: this.$t('MEMBER_RENEW.SOURCE'),
          formatter: (_, __, item) => {
            return this.$t('MEMBER_RENEW.SOURCES.' + item.source);
          },
        },
        {
          key: 'to_period',
          label: this.$t('PAGES.RENEWS.TO_PERIOD'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7',
          formatter: (_, __, item) => {

            if (item.to_period.is_infinite) {
              return this.$t('MEMBER_RENEW.TO_PERIOD_INFINITE');
            }

            return item.to_period.from + ' - ' + item.to_period.to;
          },
        },
        {
          key: 'status',
          label: this.$t('COMMON.STATUS'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7',
          formatter: (_, __, item) => {
            return this.getStatus(item.status);
          },
        },
        {
          key: 'empty',
          label: '',
          thClass: ''
        }
      ],
      send_email: false,
      send_sms: false,
      renewalLabel: 'none',
      renewShortLink: null,
      renewLink: null,
      email_template_id: null,
      sms_template_id: null,
      template_sms_options: [],
      template_email_options: [],
      is_confirm: false,
      settings: {},
      headers: [
        {
          key: 'method',
          label: this.$t('PAGES.RENEWS.SENDOUT_METHOD'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        },
        {
          key: 'created_at',
          label: this.$t('PAGES.RENEWS.TIME_SENT'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        }
      ],
    };
  },
  watch: {
    member(newValue, oldValue) {
      if (newValue) {
        this.load_member_renewals();
      }
    },
    currentCompanyId() {
      this.load_templates();
    },
    currentPeriodId() {
      const selectedPeriod = this.periods.find(item => item.id === this.currentPeriodId);

      this.next_period = selectedPeriod.next_period;
    }
  },
  mounted() {
    const selectedPeriod = this.periods.find(item => item.id === this.currentPeriodId);

    this.next_period = selectedPeriod.next_period;

    this.load_member_renewals();
    this.load_settings();
    this.load_templates();
  },
  methods: {

    refresh() {
      this.load_member_renewals();
    },

    edit_renew_clicked(id) {

      const item = this.member_renews.find(item => item.id === id);

      this.$emit('select', item);
    },

    async delete_renew_clicked(id) {
      await this.delete_renew(id);
    },

    async delete_renew(id) {
      try {
        const res = await axios.delete(`/member_renew/${id}`);

        if (res.status === 204) {
          this.member_renews = this.member_renews.filter(item => item.id !== id);
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER_RENEW.DELETED'));
          return;
        }
      }
      catch (err) {
        console.error('delete_renew', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER_RENEW.UNABLE_DELETE'));
    },

    async load_settings() {
      try {
        const res = await axios.get(`/company/admin?company_id=${this.sid}`);

        if (res.status === 200 && res.data.renew) {
          this.settings = res.data.renew;
          this.email_template_id = this.settings.email_template_id;
          this.sms_template_id = this.settings.sms_template_id;
          return;
        }

      }
      catch (err) {
        console.error('load_settings', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_GET'));
    },

    async load_templates() {
      try {
        const res = await axios.get(`/template?company_id=${this.currentCompanyId}&light=true`);

        if (res.status === 200) {

          const templates = res.data;

          this.template_sms_options = [];
          this.template_email_options = [];

          for (const t of templates) {
            if (t.is_sms) {
              this.template_sms_options.push({ value: t.template_id, text: t.name });
            }
            else {
              this.template_email_options.push({ value: t.template_id, text: t.name });
            }
          }

          return;
        }
      }
      catch (err) {
        console.error('load_templates', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
    },

    editRenew(id) {
      this.selectedRenew = this.member_renews.find(item => item.id === id);
      this.calcRenewShortLink(this.selectedRenew);
    },

    getPaymentStatus(status) {
      return {
        WAIT: this.$t('COMMON.WAITING'),
        PAID: this.$t('COMMON.PAID'),
        LATE: this.$t('COMMON.LATE')
      }[status];
    },

    getStatus(status) {
      return {
        ACTIVE: this.$t('PAGES.RENEWS.ACTIVE'),
        STARTED_LINK: this.$t('PAGES.RENEWS.STARTED_LINK'),
        INVOICED: this.$t('PAGES.RENEWS.INVOICED'),
        DELETED: this.$t('PAGES.RENEWS.DELETED'),
        DONE: this.$t('PAGES.RENEWS.DONE'),
        AWAITING_PAYMENT: this.$t('PAGES.RENEWS.AWAITING_PAYMENT'),
        TIME_OUT: this.$t('PAGES.RENEWS.TIME_OUT')
      }[status];
    },
    calcMethodStr(method) {
      switch (method) {
        case 'EMAIL': return this.$t('MEMBER.EMAIL');
        case 'SMS': return 'SMS';
        case 'POST': return this.$t('COMMON.BYPOST');
      }
      return 'Okänt';
    },
    showConfirmSMS() {
      this.$refs['renewalSMS'].show();
    },
    showConfirm(val) {
      this.$refs['renewal'].show();
    },
    sendRenewalBySMS() {
      const renew_ids = [this.selectedRenew.id];

      this.postBatchSMS(renew_ids);
    },

    postBatchSMS(renew_ids) {
      axios
        .post('/member_renew/batch/sms', { renew_ids })
        .then(res => {
          if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SMS.SMS_SENT'));
            const that = this;

            this.selectedRenew.sendouts.push({
              method: 'SMS',
              id: 999999999,
              member_renew_id: this.selectedRenew.id,
              created_at: 'Väntar...'
            });

            setTimeout(function(){
              that.loadActiveRenewal();
            }, 8000);

          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SMS.UNABLE_SEND'));
          }
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SMS.UNABLE_SEND'));
          console.error(err);
        });
    },

    sendRenewalByEmail() {

      const payload = {
        member_id: this.member_id
      };
      axios
        //.post(`/member/sendrenew`, payload)
        .post(`/member_renew/send/${this.selectedRenew.id}`, { method: 'EMAIL' })
        .then(res => {
          if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.RENEWS.LINK_SENT_EMAIL'));
            if (this.selectedRenew.sendouts === undefined || this.selectedRenew.sendouts === null) {
              this.selectedRenew.sendouts = [];
            }

            this.selectedRenew.sendouts.push(res.data);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_LINK_SENT_EMAIL'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_LINK_SENT_EMAIL'));
        });
    },
    createRenew() {

      if (this.next_period === null || this.next_period === undefined) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.NEXT_PERIOD_ERROR'));
        return;
      }

      axios
        .post(`/member_renew/${this.member.member_id}`,
        {
          company_id: this.currentCompanyId,
          period_id: this.currentPeriodId,
          to_period_id: this.next_period.id,
          email_option: this.send_email ? 'DO_SEND' : 'DONT_SEND',
          sms_option: this.send_sms ? 'DO_SEND' : 'DONT_SEND',
          email_template_id: this.email_template_id,
          sms_template_id: this.sms_template_id,
          is_confirm: this.is_confirm
        })
        .then(res => {
            if (res.status === 201) {
              this.member_renews.push(res.data);
              this.selectedRenew = res.data;
              this.renewalLabel = 'active';
              this.calcRenewShortLink(this.selectedRenew);
            }
            else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_CREATE'));
            }
        })
        .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_CREATE'));
        });
    },
    calcRenewShortLink(renew) {
      this.renewShortLink = renew.is_confirm ? `${window.location.origin}/x/${renew.confirmlinkstr}` : `${window.location.origin}/x/${renew.linkstr}`;

      return this.renewShortLink;
    },

    async load_member_renewals() {
      try {
        const res = await axios.get(`/member_renew/member/${this.member.member_id}`);

        if (res.status === 200) {
          this.member_renews = res.data;
          return this.member_renews;
        }
      }
      catch (err) {
        console.error('load_renewals', err);
      }

      return [];
    },
  }
};
</script>
<style scoped>

</style>
