<template>
  <div>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
        @click.prevent="$emit('create')"
        ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('MEMBER_EDUCATION.CREATE_MEMBER_EDUCATION')}}</a
      >

    </div>

    <b-row class="mb-4">
      <b-col lg="6" md="6" sm="12">
        <b-form-input
          id="filter-input"
          v-model="filters.text"
          type="search"
          style="max-width:621px"
          :placeholder="$t('COMMON.SEARCH')"
        ></b-form-input>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100">
          <b-form-select
            id="per-page"
            class="mr-sm-2 mx-0"
            style="max-width: 100px;"
            v-model="per_page"
            :options="[{ value: 25, text: '25' }, { value: 100, text: '100' }, { value: 250, text: '250' }]"
          ></b-form-select>
          <b-pagination
            class="mb-0"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="member_education-table"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>

    <p v-if="total_rows > 0"><strong>{{$t('COMMON.NUM_RECORDS')}}:</strong> {{ total_rows }}</p>

    <!-- Table -->
    <div class="table-container">
      <b-table
        class="table-striped"
        :items="ajax_pagination"
        :fields="fields"
        :per-page="per_page"
        :current-page="current_page"
        head-variant="light"
        ref="member_education-table"
      >
      <template #cell(action)="data">
        <div class="text-right" v-if="!view_only">
          <a
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="edit_item_clicked(data.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>
          <a
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="delete_item_clicked(data.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>
      </b-table>

      <p class="ml-4" v-if="total_rows === 0">{{$t('MEMBER_EDUCATION.NO_RECORDS_FOUND')}}</p>

    </div>

  </div>
</template>

<style lang="scss" scoped>
:deep .td-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

</style>

<script>
import axios from 'axios';
import Confirm from '@/view/components/Confirm.vue';
import { mapGetters } from 'vuex';
import EducationTable from '@/view/pages/ml/education/EducationTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'MemberEducationsTable',
  components: {
    Confirm,
    EducationTable
  },
  mixins: [ toasts ],
  props: ['member'],
  emits: ['select', 'delete', 'create'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods'])
  },
  data() {
    return {
      current_page: 1,
      per_page: 25,
      filters: {
        text: null,
        member_id: null
      },

      total_rows: 0,
      fields: [
        {
          key: 'education.name',
          label: this.$t('EDUCATION.NAME'),
          tdClass: 'td-overflow'
        },
        {
          key: 'education.code',
          label: this.$t('EDUCATION.CODE')
        },
        {
          key: 'education.type',
          label: this.$t('EDUCATION.TYPE'),
          formatter: (_, __, item) => {
            return this.getTypeStr(item.education.type);
          },
        },
        {
          key: 'period.name',
          label: this.$t('EDUCATION.PERIOD'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        },
        {
          key: 'empty',
          label: '',
          thClass: ''
        }
      ],
    };
  },
  watch: {
    'filters.text'() {
      this.refresh();
    },
    member(new_value) {
      if (new_value.member_id) { // only refresh when member id is valid
        this.refresh();
      }
    },
  },
  mounted() {

  },
  methods: {

    getTypeStr(type) {
      switch (type) {
        case 'NONE': return this.$t('EDUCATION.TYPES.NONE');
        case 'PROGRAM': return this.$t('EDUCATION.TYPES.PROGRAM');
        case 'COURSE': return this.$t('EDUCATION.TYPES.COURSE');
        case 'FREE_COURSE': return this.$t('EDUCATION.TYPES.FREE_COURSE');
      }

      return this.$t('EDUCATION.TYPES.UNKNOWN')
    },

    async connect_education(member_id, education) {

      try {
        this.$refs['selectEducationModal'].hide();

        const res = await axios.post(`/education/${education.id}/member/${member_id}`, { period_id: this.currentPeriodId });

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER_EDUCATION.ADDED'));
          this.refresh();
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER_EDUCATION.UNABLE_ADD'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER_EDUCATION.UNABLE_ADD'));
      }

    },


    ajax_pagination: function(ctx, callback) {
      const vm = this;

      let params = {
        pageNo: ctx.currentPage,
        pageSize: ctx.perPage,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_page_count();
      this.load_pagination(callback);

      return null;
    },

    refresh() {
      this.$refs['member_education-table'].refresh();
    },

    get_options() {
      return {
        member_id: this.member.member_id,
        text: this.filters.text
      };
    },

    async load_pagination(callback) {
      const options = this.get_options();

      if (!options.member_id) {
        if (callback) {
          callback(arr || []);
          return;
        }
      }

      try {
        const res = await axios.post(`/member_education/pagination`, { options: options, orderBy: this.orderBy, page: this.current_page, limit: this.per_page });

        if (res.status === 200) {
          var arr = res.data;

          if (callback) {
            callback(arr || []);
            return;
          }
        }
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        console.error('pagination get error', err);
      }

    },

    async get_page_count() {

      const options = this.get_options();

      try {
        const res = await axios.post(`/member_education/pagination/count`, { options: options, orderBy: this.orderBy });

        if (res.status === 200) {
          this.total_rows = res.data.count;
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
      }

    },

    async edit_item_clicked(item) {
      this.$nextTick(() => {
        this.$emit('select', item);
      });
    },

    async delete_item_clicked(item) {
      this.$emit('delete', item);
    },


    getTypeStr(type) {
      switch (type) {
        case 'NONE': return this.$t('EDUCATION.TYPES.NONE');
        case 'PROGRAM': return this.$t('EDUCATION.TYPES.PROGRAM');
        case 'COURSE': return this.$t('EDUCATION.TYPES.COURSE');
        case 'FREE_COURSE': return this.$t('EDUCATION.TYPES.FREE_COURSE');
      }

      return this.$t('EDUCATION.TYPES.UNKNOWN')
    },
  }
};
</script>
<style scoped>

</style>
