<template>
  <b-modal ref="deactivateModal" hide-footer
    :title="$t('MEMBER.DEACTIVATE_UPDATE')">
    <b-row v-if="!deactivated">
      <b-col>
        <div class="alert alert-custom alert-notice alert-light-info fade show" role="alert">
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text">{{ $t('MEMBER.DEACTIVATE_AT_INFO') }}</div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!deactivated">
      <b-col>

        <b-form-group
          :label="$t('MEMBER.DEACTIVATE_AT')"
          label-for="member-deactivate_at"
        >
          <memlist-date-picker v-model="form.deactivate_at"></memlist-date-picker>
        </b-form-group>

      </b-col>
    </b-row>
    <b-row class="mt-8 mb-8">
      <b-col lg="6">
        <b-button
          v-if="!deactivated"
          class="mb-4"
          variant="success"
          @click="onDeactivateNowClicked">{{$t('MEMBER.DEACTIVATE_NOW')}}</b-button>

        <b-button
          v-if="deactivated"
          class="mb-4"
          variant="success"
          @click="onActivateNowClicked">{{$t('MEMBER.ACTIVATE_NOW')}}</b-button>

        <b-button
          v-if="form.deactivate_at && form.deactivate_at !== deactivate_at"
          class=""
          variant="success"
          @click="onDeactivateAtClicked">{{$t('MEMBER.DEACTIVATE_AT')}} {{ form.deactivate_at }}</b-button>
      </b-col>
      <b-col lg="6">
        <b-button class="" variant="outline-secondary" block @click="onCancel"
          >{{$t('COMMON.CANCEL')}}</b-button
        >
      </b-col>
    </b-row>
  </b-modal>
</template>

<style lang="scss" scoped>
</style>
<script>
import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'member-deactivate-modal',
  data() {
    return {
      form: {
        deactivated: null,
        deactivate_at: null
      }
    };
  },
  mixins: [ toasts ],
  props: ['member_id','deactivated','deactivated_at'],
  emits: ['deactivatedUpdated', 'cancel'],
  async mounted() {},
  components: {},
  methods: {
    show() {
      this.$refs['deactivateModal'].show();
    },
    onCancel() {
      this.$refs['deactivateModal'].hide();

      this.$emit('cancel');
    },

    onActivateNowClicked() {
      this.activateMember(this.member_id);
    },

    onDeactivateAtClicked() {
      this.deactivateMember(this.member_id, this.form.deactivate_at);
    },

    onDeactivateNowClicked() {
      this.deactivateNowMember(this.member_id);
    },

    deactivateNowMember(member_id) {
      axios
        .put(`/member/activate/${member_id}`, { deactivated: true })
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.ACTIVATION_UPDATED'));

            this.$emit('deactivatedUpdated', res.data.member_id, res.data.deactivated, res.data.deactivate_at);

            this.$refs['deactivateModal'].hide();
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_UPDATE_ACTIVATE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_UPDATE_ACTIVATE'));
        });
    },

    deactivateMember(member_id, deactivate_at) {
      axios
        .put(`/member/activate/${member_id}`, { deactivate_at })
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.ACTIVATION_UPDATED'));

            this.$emit('deactivatedUpdated', res.data.member_id, res.data.deactivated, res.data.deactivate_at);

            this.$refs['deactivateModal'].hide();
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_UPDATE_ACTIVATE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_UPDATE_ACTIVATE'));
        });
    },

    activateMember(member_id) {
      axios
        .put(`/member/activate/${member_id}`, { deactivated: false })
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.ACTIVATION_UPDATED'));

            this.$emit('deactivatedUpdated', res.data.member_id, res.data.deactivated, res.data.deactivate_at);

            this.$refs['deactivateModal'].hide();
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_UPDATE_ACTIVATE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_UPDATE_ACTIVATE'));
        });
    },


  },
  computed: {

  }
};
</script>
