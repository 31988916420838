<template>

  <b-modal title="Registrera betalning" ref="modal" v-model="showManualPaymentModal" hide-footer>
    <b-row v-if="showError">
        <b-col cols="12">
            <b-alert variant="danger" show>{{ errorText }}</b-alert>
        </b-col>
    </b-row>

    <div v-if="!waiting">

      <b-row>
        <b-col cols="6">
          <b-form-group id="input-group-total_sek" :label="$t('INVOICE.TOTAL_SEK')" label-for="input-total_sek">
              <b-form-input
              id="total_sek"
              v-model="amount"
              disabled
              ></b-form-input>
          </b-form-group>

        </b-col>
        <b-col cols="6">
          <b-form-group id="input-group-amount_paid" :label="$t('INVOICE.AMOUNT_PAID')" label-for="input-amount_paid">
              <b-form-input
              id="amount_paid"
              v-model="amount_paid"
              disabled
              ></b-form-input>
          </b-form-group>

        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group id="input-group-paid_at" :label="$t('INVOICE.PAID_AT')" label-for="input-paid_at">
            <memlist-date-picker v-model="paid_at"></memlist-date-picker>

          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group id="input-group-sum" :label="$t('INVOICE.SUM')"  label-for="input-sum">
            <b-form-input
              id="sum"
              v-model="sum"
            ></b-form-input>
          </b-form-group>

          </b-col>
      </b-row>

      <b-row>
        <b-col lg="12">
          <div class="d-flex align-items-center mb-6">
            <label
                class="checkbox checkbox-lg checkbox-outline checkbox-success"
            >
                <input
                type="checkbox"
                name=""
                v-model="mark_membership_paid"
                @click="mark_membership_paid = !mark_membership_paid"
                />
                <span></span>
            </label>
            <span class="ml-3 cursor-pointer">{{ $t('INVOICE.MARK_PAID_MEMBERSHIP')}}</span>
            </div>
        </b-col>
      </b-row>

      <b-row class="mt-6">
        <b-col lg="6">
          <b-button variant="outline-primary" @click="cancel">
            {{ $t('COMMON.CANCEL') }}
          </b-button>
        </b-col>
        <b-col lg="6">
          <b-button variant="outline-success" :disabled="inputDisabled" @click="save">
            {{ $t('COMMON.SAVE') }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>

</template>
<style lang="scss" scoped>
</style>
<script>
import axios from 'axios';

import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'ManualPaymentModal',
  components: {
    Confirm,
  },
  mixins: [ toasts ],
  props: ['invoice_id','amount','amount_paid'],
  emits: ['on_manual_payment', 'cancel'],
  data() {
    return {
      mark_membership_paid: false,
      paid_at: null,
      sum: 0,
      waiting: false,
      showError: false,
      errorText: 'Ett fel uppstod',
      showManualPaymentModal: false,
    };
  },
  computed: {
    inputDisabled() {
      return !(this.paid_at !== null && this.sum > 0);
    }
  },
  async mounted() {

  },
  methods: {
    show() {
      this.showManualPaymentModal = true;
    },
    close() {
      this.showManualPaymentModal = false;
    },
    cancel() {
      this.$refs['modal'].hide();
      this.$emit('cancel');
    },
    async save() {
      this.waiting = true;
      this.sum = parseFloat(this.sum + '');
      const body = {
        sum: this.sum,
        paid_at: this.paid_at,
        mark_membership_paid: this.mark_membership_paid
      }

      await this.post_payment(body);
    },

    async post_payment(body) {
      try {
        const res = await axios.post(`/invoice/payment/${this.invoice_id}`, body)

        this.waiting = false;

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('INVOICE.PAYMENT_CREATED') );
          this.$emit('on_manual_payment', this.invoice_id, body.sum, body.mark_membership_paid)
          this.close();
        }
        else {
          console.error(res.data);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_CREATE_PAYMENT'));
        }
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_CREATE_PAYMENT'));
      }
    }

  }
};
</script>
<style scoped>
</style>

