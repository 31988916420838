<template>
  <div>

    <b-row>
      <b-col cols="12">
        <b-table
          class="table-striped"
          :fields="fields"
          :items="items"
          head-variant="light"
          id="sendouts-table"
        >
          <template #cell(empty)="data">
            <div class="text-right">

            </div>
          </template>
        </b-table>
        <p class="ml-4" v-if="!items || items.length === 0">{{$t('MEMBER_RENEW.SENDOUT_NONE_FOUND')}}</p>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';

import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'MemberRenewSendoutsTable',
  components: {

  },
  mixins: [ toasts ],
  props: ['member', 'items'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies'])
  },
  data() {
    return {

      fields: [
        {
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        },
        {
          key: 'method',
          label: this.$t('MEMBER_RENEW.METHOD'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7',
          formatter: (_, __, item) => {

            return this.$t('MEMBER_RENEW.SENDOUT_METHODS.' + item.method);
          },
        },

        {
          key: 'empty',
          label: '',
          thClass: ''
        }
      ],
      data: null
    };
  },
  watch: {

  },
  mounted() {

  },
  methods: {

  }
};
</script>
<style scoped>
:deep .table > tbody > tr > td {
  vertical-align: middle;
}
#app .container {
  background-color: #293039;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100%;
}
#bar {
  flex: 1;
  background-color: #293039;
  color: white;
  padding: 10px;
  display: flex;
  max-height: 65px;
}
#bar h1 {
  flex: 1;
  font-size: 16px;
  text-align: left;
}
#bar button {
  min-width: 80px;
  cursor: pointer;
}
@media screen and (min-width: 500px) {
  .table-responsive {
      overflow: visible;
  }
}

</style>
