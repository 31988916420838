import { render, staticRenderFns } from "./MemberPropertyAssignEditor.vue?vue&type=template&id=14aefa04&scoped=true"
import script from "./MemberPropertyAssignEditor.vue?vue&type=script&lang=js"
export * from "./MemberPropertyAssignEditor.vue?vue&type=script&lang=js"
import style0 from "./MemberPropertyAssignEditor.vue?vue&type=style&index=0&id=14aefa04&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14aefa04",
  null
  
)

export default component.exports