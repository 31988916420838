<template>
  <div class="container">

    <!--<b-modal
      :title="creating ? $t('MEMBERSHIP.CREATING') : $t('MEMBERSHIP.EDITING')"
      ref="edit-form"
      hide-footer
      >

      <MemberCompanyForm
        :member="member"
        :item="selected_item"
        :creating="creating"
        @cancel="() => { this.$refs['edit-form'].hide(); }"
        @created="member_company_created"
        @updated="member_company_updated"
      />


    </b-modal>-->

    <div class="table-container">
      <b-table
        id="member-table"
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        :fields="fields"
        :items="items"
        head-variant="light"
        ref="memberTable"
      >
      <!--<template #cell(action)="data">
        <div class="text-right" v-if="!viewOnly">
          <a
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="edit_row_clicked(data.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>
          <a
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="delete_row_clicked(data.item.item_id)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>-->
      </b-table>

      <p class="ml-4" v-if="local_items.length === 0">{{ $t('MEMBER_EVENT.NO_FOUND') }}</p>

    </div>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import ColorPickerInput from '@/view/components/ColorPickerInput';



export default {

  name: 'MemberEvents',

  props: ['member','items','company'],
  emits: ['save_data'],
  mixins: [ toasts ],

  components: {
    TipLabel,
    RequiredFieldsInfo,
    ColorPickerInput,
  },

  watch: {
    items: {
      deep: true,
      handler(val) {
        this.local_items = { ...val };
      }
    },
  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {
    async save_data() {

      this.$emit('save_data', this.member);

    },

    item_created(item) {
      // add to top
      this.local_items.unshift(item);
    },

    item_updated(item) {
      // update item in list
      const index = this.local_items.findIndex((i) => {
        return i.member_company_id === item.member_company_id;
      });

      this.local_items[index] = item;
    },

    async edit_row_clicked(item) {
      this.selected_item = item;
      this.creating = false;

      this.$nextTick(()=>{
        this.$refs['edit-form'].show();
      });
    },

    async delete_row_clicked(item) {

    },

    async on_submit() {},

    validate_state(field, state) {}

  },

  mounted() {
    if (this.items) {
      this.local_items = { ...this.items };
    }
    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {

      creating: true,

      selected_item: {},

      fields: [
        {
          key: 'event.name',
          label: this.$t('EVENT.EVENT'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'ts_signup',
          label: this.$t('MEMBER_EVENT.SIGNUP'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'is_paid',
          label: this.$t('MEMBERSHIP.IS_PAID'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            return item.is_paid ? this.$t('COMMON.YES') : this.$t('COMMON.NO');
          }
        },
        {
          key: 'action',
          label: '',
          tdClass: 'td-actions',
          thClass: 'td-actions',
          sortable: false
        }
      ],

      show_form: true,
      local_items: [],
    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
