
import axios from 'axios';

/**
 * 
 * 
 * @param {*} file 
 * @param {{ ladok_id: string, file_type: string, company_id: string }} options 
 */
export async function upload_file(file, options) {
  let formData = new FormData();
  formData.append('file', file);

  for (const key in options) {
    formData.append(key, options[key]);
  }

  const res = await axios.post('/fileupload',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

  if (res.status === 201) {
    return res.data.fileobjs[0];
  }

  throw 'unable to create file';
}
