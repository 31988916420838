import { render, staticRenderFns } from "./MemberCompaniesView.vue?vue&type=template&id=21d93f7b&scoped=true"
import script from "./MemberCompaniesView.vue?vue&type=script&lang=js"
export * from "./MemberCompaniesView.vue?vue&type=script&lang=js"
import style0 from "./MemberCompaniesView.vue?vue&type=style&index=0&id=21d93f7b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d93f7b",
  null
  
)

export default component.exports