<template>
  <b-modal ref="error-popup" hide-footer>
    <div class="row">
      <div class="col-12 text-center">
        <i class="fa fa-minus-circle" style="font-size: 42px; color: #fe0000;"></i>
      </div>
    </div>
    <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
      <div class="col-12 text-center">
        <h4 class="event-name" style="text-align: center;">{{ header }}</h4>
        <br />
        <p>
          {{ message }}
        </p>
      </div>
    </div>
  </b-modal>
</template>
  
<style lang="scss" scoped></style>
  
<script>
export default {
  name: 'error-header-message-popup',
  data() {
    return {
      
    };
  },
  props: {
    header: String,
    message: String
  },
  async mounted() {

    if (this.error === "" || this.error === null) {
      return;
    }

    if (!(this.error in this.defined_errors)) {
      console.error('error message not defined: ' + this.error);
      return;
    }

    this.show()
  },
  watch: {
    error() {

      if (this.header === "" || this.header === null || this.header === undefined) {
        return;
      }

      this.show();
    }
  },
  methods: {
    show() {
      this.$refs['error-popup'].show();
    },
    onOk() {
      this.$refs['error-popup'].hide();
    },
  },
  
};
</script>
