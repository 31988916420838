<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('PERIOD.PERIOD')">

              <b-form-select
                @change="on_period_change"
                v-model="local_invoice.period_id"
                :options="period_options"
                ></b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('INVOICE.INVOICE_TEMPLATE')">

              <b-form-select
                @change="on_invoice_template_change"
                v-model="local_invoice.invoice_template_id"
                :options="invoice_template_options"></b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('INVOICE.DUE_DATE')"
            >
              <memlist-date-picker v-model="local_invoice.due_date"></memlist-date-picker>
              <b-form-invalid-feedback>{{$t('PAGES.CAMPAIGNS.INVALID_DATE')}}</b-form-invalid-feedback>

            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-row style="margin-top: 20px !important; margin-bottom: 5px !important;">
          <b-col>
            <h5>{{ $t('INVOICE.SELECT_MEMBERSHIPS') }}</h5>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <MemberInvoiceMembershipsTable
              v-if="member_companies"
              :member="member"
              :items="member_companies"
              @selected="member_company_rows_selected"
            />
          </b-col>
        </b-row>

        <hr />

        <b-row style="margin-top: 20px !important; margin-bottom: 5px !important;">
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('INVOICE.AMOUNT')">

              <b-form-input
              :disabled="true"
              v-model="local_invoice.amount" valueType="format" />
              <b-form-invalid-feedback>{{$t('INVOICE.INVALID_AMOUNT')}}</b-form-invalid-feedback>

            </b-form-group>
          </b-col>
        </b-row>

        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>

            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_submit"
              @close="$emit('cancel')"
              ref="saveButton"
            />
          </b-col>
        </b-row>
      </b-form>

    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import MemberInvoiceMembershipsTable from '@/view/components/member_editor_v2/sections/member_invoice/MemberInvoiceMembershipsTable.vue';


export default {

  name: 'MemberCompanyForm',

  props: ['member','item','creating'],
  emits: ['created', 'updated', 'cancel'],

  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    MemberInvoiceMembershipsTable
  },

  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_invoice = { ...this.item };
      }
    },

    'local_invoice.company_id'() {
      this.get_all_shop_items(this.item.company_id);
    },
    'member.member_id'() {
      this.load_member_companies(this.member.member_id);
    }
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    disabledCreateButton() {
      return !this.local_invoice.company_id || !this.local_invoice.period_id;
    }
  },


  mounted() {

    if (this.item) {
      this.local_invoice = { ...this.item }
      this.get_all_shop_items(this.item.company_id);
    }
    else {
      this.get_all_shop_items(this.currentCompanyId);
    }

    this.company_options = this.companies.map((item) => ({ text: item.name, value: item.company_id }));
    this.period_options = this.periods.map((item) => ({ text: item.name, value: item.id }));


    this.load_invoice_templates();
    this.load_member_companies(this.member.member_id);

  },

  methods: {

    member_company_rows_selected(rows) {

      let sum = 0;

      for (const i of rows) {
        if (!i.added) {
          continue;
        }

        sum += i.shop_item.amount;
      }

      this.local_invoice.amount = sum;

      this.local_invoice.member_company_ids = rows.filter(item => item.added).map(item => ( item.member_company_id ));

      this.local_invoice = { ...this.local_invoice }
    },

    async load_member_companies(member_id) {

      try {
        const res = await axios.get(`/member_company/member/${member_id}`);

        if (!this.local_invoice.member_company_ids) {
          this.local_invoice.member_company_ids = [];
        }

        if (res.status === 200) {
          this.member_companies = res.data;

          for (const mc of this.member_companies) {

            const found = this.local_invoice.member_company_ids.find(item => item === mc.member_company_id);

            if (found) {
              mc.added = true;
            }
            else {
              mc.added = false;
            }
          }

          this.member_companies = [ ...this.member_companies ];

          return;
        }
      }
      catch (err) {
        console.error('load_member_companies', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER_COMPANY.UNABLE_LIST'));
    },

    on_invoice_template_change() {},

    async load_invoice_templates() {

      try {
        const res = await axios.get(`/invoice_template`)


        if (res.status === 200) {
          const templates = res.data;

          for (var i = 0; i < templates.length; ++i) {
            this.invoice_template_options.push({
              value: templates[i].id,
              text: templates[i].name
            });
          }
        }

        return;
      }
      catch (err) {
        console.error('load_invoice_templates', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));

    },


    async on_submit() {

      if (this.creating) {
        await this.create_invoice();
      }
      else {
        await this.update_invoice();
      }

      this.$refs['saveButton'].stop();
    },

    async create_invoice() {
      try {

        const res = await axios.post(`/invoice/member/${this.member.member_id}`, this.local_invoice);

        if (res.status === 201) {
          this.$emit('created', res.data);
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_CREATE'));
      }
    },

    async update_invoice() {
      try {

        const res = await axios.put(`/invoice/${this.local_invoice.invoice_id}`, this.local_invoice);

        if (res.status === 200) {
          this.$emit('updated', res.data);
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_UPDATE'));
      }
    },

    async get_all_shop_items(company_id) {
      try {
        const res = await axios .get(`/shop_item/all`);

        if (res.status === 200) {
          this.shop_items = res.data;
          this.shop_items_options = [];

          for (const si of res.data) {

            if (si.is_ticket) {
              continue;
            }

            if (company_id !== null && si.company_id !== company_id) {
              continue;
            }

            this.shop_items_options.push({ value: si.shop_item_id, text: si.name });

          }
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta butiksartiklar');
      }

    },

  },


  data() {
    return {

      local_invoice: {},
      member_companies: [],

      paying_options: [
        { value: true, text: this.$t('COMMON.PAID') },
        { value: false, text: this.$t('COMMON.NOT_PAID') }
      ],

      company_options: [],
      period_options: [],
      shop_items_options: [],
      show_form: true,
      invoice_template_options: []

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
