<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <!-- Add membership begin -->
      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">

        <b-row v-if="local_item.public_id">
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('MEMBER.PUBLIC_ID')">

              <b-form-input
                :disabled="true"
                v-model="local_item.public_id"
              />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="local_item.person_id">
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('PARENT.PERSON_ID')">

              <b-form-input
                :disabled="true"
                v-model="local_item.person_id"
              />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('MEMBER.FIRSTNAME')">

              <b-form-input
                v-model="local_item.firstname"
                :state="validate_state({ dirty: v$['local_item']['firstname'].$dirty, error: v$['local_item']['firstname'].$error })"
              />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('MEMBER.LASTNAME')">

              <b-form-input
                v-model="local_item.lastname"
                :state="validate_state({ dirty: v$['local_item']['lastname'].$dirty, error: v$['local_item']['lastname'].$error })"
              />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('MEMBER.PERSONNR')">

              <b-form-input
                v-model="local_item.in_personnr"
              />

            </b-form-group>
          </b-col>
        </b-row>


        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('MEMBER.EMAIL')">

              <b-form-input
                v-model="local_item.email"
                :state="validate_state({ dirty: v$['local_item']['email'].$dirty, error: v$['local_item']['email'].$error })"
              />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('MEMBER.PHONE')">

              <b-form-input
                v-model="local_item.phone"
              />

            </b-form-group>
          </b-col>
        </b-row>


        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>

            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_submit"
              @close="$emit('cancel')"
              ref="saveButton" />
          </b-col>
        </b-row>
      </b-form>


    </div>
  </div>
</template>


<script>
import { useVuelidate } from '@vuelidate/core'
import { minLength, required, email, requiredIf } from '@vuelidate/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';


import axios from 'axios';
import dayjs from 'dayjs';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {

  name: 'MemberCompanyForm',

  props: ['member', 'item'],
  emits: ['created', 'updated', 'save_data', 'cancel'],
  mixins: [toasts],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    }
  },

  // --- begin vuelidate --- //
  setup() {
    return { v$: useVuelidate() }
  },

  validations() {
    return {
      local_item: {
        firstname: { required },
        lastname: { required },
        email: { required }
      }
    }
  },

  // -- end vuelidate -- //

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),

  },


  mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }

  },

  methods: {

    validate_state(state) {
      return state.dirty ? !state.error : null;
    },

    async on_submit() {

      const valid = await this.v$.$validate();

      if (!valid) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.FORM_VALIDATION_FAILED'));
        this.$refs['saveButton'].stop();
        return;
      }

      if (!this.local_item.id) {
        await this.create_parent();
      }
      else {
        await this.update_parent();
      }

      this.$refs['saveButton'].stop();
    },



    async create_parent() {
      try {

        const res = await axios.post(`/member/parent/${this.member.member_id}`, this.local_item)

        if (res.status === 201) {

          const p = res.data.person ? res.data.person : res.data.member;

          if (!p.id) {
            if (p.member_id) {
              p.id = p.member_id;
            }
            else {
              p.id = p.person_id;
            }
          }

          this.$emit('created', p);
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PARENT.UNABLE_CREATE'));
      }
    },

    async update_parent() {
      try {

        if (this.local_item.member_id) {
          const res = await axios.put(`/member/${this.local_item.member_id}`, this.local_item);

          if (res.status === 200) {
            this.$emit('updated', res.data);
          }
        }
        else {
          const res = await axios.put(`/person/${this.local_item.person_id}`, this.local_item);

          if (res.status === 200) {
            this.$emit('updated', res.data);
          }
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PARENT.UNABLE_UPDATE'));
      }
    },


    async save_data() {

      this.$emit('save_data', this.local_item);

    },


  },


  data() {
    return {

      local_item: {},


    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
