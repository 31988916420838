import { render, staticRenderFns } from "./MemberDeactivateModal.vue?vue&type=template&id=2bffe411&scoped=true"
import script from "./MemberDeactivateModal.vue?vue&type=script&lang=js"
export * from "./MemberDeactivateModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bffe411",
  null
  
)

export default component.exports