<template>
  <div class="container">

    <b-row>
      <b-col md="12" sm="12">
        <h5>{{ $t('MEMBER_EDITOR.CONTACT_INFORMATION') }}</h5>

        <div class="px-1">

          <b-form-group
            v-if="unlocked_field('address')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.ADDRESS')">
            <b-form-input
              @change="change_data"
              size="sm"
              :type="secret_type"
              v-model="local_member.address"/>
          </b-form-group>

          <b-form-group
            v-if="unlocked_field('co')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.CO')">
            <b-form-input
              @change="change_data"
              size="sm"
              :type="secret_type"
              v-model="local_member.co"/>
          </b-form-group>


          <b-form-group
            v-if="unlocked_field('zipcode')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.ZIPCODE')">
            <b-form-input
              @change="change_data"
              size="sm"
              :type="secret_type"
              v-model="local_member.zipcode"/>
          </b-form-group>

          <b-form-group
            v-if="unlocked_field('post')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.POST')">
            <b-form-input
              @change="change_data"
              size="sm"
              :type="secret_type"
              v-model="local_member.post"/>
          </b-form-group>

          <b-form-group
            v-if="unlocked_field('region_code')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.REGION_CODE')">

            <b-form-select
              @change="change_data"
              :options="region_code_options"
              v-model="local_member.region_code"/>

          </b-form-group>

          <b-form-group
            v-if="unlocked_field('commune_code')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.COMMUNE')">
            <b-form-select
              @change="change_data"
              :options="commune_code_options"
              v-model="local_member.commune_code"/>
          </b-form-group>


          <b-form-group
            v-if="unlocked_field('country')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.COUNTRY')">
            <b-form-select
              @change="change_data"
              :options="$t('COUNTRIES')"
              text-field="value"
              value-field="key"
              v-model="local_member.country"/>
          </b-form-group>

        </div>
      </b-col>

    </b-row>

    <hr />

    <b-row class="" v-if="show_invoice_address">
      <b-col md="12" sm="12">
        <h5>{{ $t('MEMBER_EDITOR.INVOICE_ADDRESS') }}</h5>

        <div class="px-1">
          <b-form-group
            v-if="unlocked_field('invoice_firstname')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.FIRSTNAME')">
            <b-form-input
              @change="change_data"
              size="sm"
              :type="secret_type"
              v-model="local_member.invoice_firstname"/>
          </b-form-group>

          <b-form-group
            v-if="unlocked_field('invoice_lastname')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.LASTNAME')">
            <b-form-input
              @change="change_data"
              :type="secret_type"
              size="sm"
              v-model="local_member.invoice_lastname"/>
          </b-form-group>

          <b-form-group
            v-if="unlocked_field('invoice_address')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.ADDRESS')">
            <b-form-input
              @change="change_data"
              size="sm"
              :type="secret_type"
              v-model="local_member.invoice_address"/>
          </b-form-group>

          <b-form-group
            v-if="unlocked_field('invoice_co')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.CO')">
            <b-form-input
              @change="change_data"
              size="sm"
              :type="secret_type"
              v-model="local_member.invoice_co"/>
          </b-form-group>


          <b-form-group
            v-if="unlocked_field('invoice_zipcode')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.ZIPCODE')">
            <b-form-input
              @change="change_data"
              :type="secret_type"
              size="sm"
              v-model="local_member.invoice_zipcode"/>
          </b-form-group>

          <b-form-group
            v-if="unlocked_field('invoice_post')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.POST')">
            <b-form-input
              @change="change_data"
              :type="secret_type"
              size="sm"
              v-model="local_member.invoice_post"/>
          </b-form-group>


          <b-form-group
            v-if="unlocked_field('invoice_country')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.COUNTRY')">
            <b-form-select
              @change="change_data"
              :options="$t('COUNTRIES')"
              text-field="value"
              value-field="key"
              v-model="local_member.invoice_country"/>
          </b-form-group>

          <b-form-group
            v-if="unlocked_field('invoice_email')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.EMAIL')">
            <b-form-input
              @change="change_data"
              :type="secret_type"
              size="sm"
              v-model="local_member.invoice_email"/>
          </b-form-group>

          <b-form-group
            v-if="unlocked_field('invoice_phone')"
            label-cols-md="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="4"
            :label="$t('MEMBER.PHONE')">
            <b-form-input
              @change="change_data"
              size="sm"
              :type="secret_type"
              v-model="local_member.invoice_phone"/>
          </b-form-group>

        </div>
      </b-col>

    </b-row>

    <RightSaveButton
      ref="save-button"
      :text="
        !this.creating
          ? $t('COMMON.SAVE')
          : $t('COMMON.CREATE')
      "
      @clicked="submit_clicked()"
    />

  </div>

</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

.container {
  content: "";
  display: block;
  padding-bottom: 48px;
}
</style>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import MemberPasswordUpdate from '@/view/components/member_editor_v2/MemberPasswordUpdate.vue';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {

  name: 'MemberEditorContactInformation',

  props: ['member','unlocks'],
  emits: ['create', 'update', 'change'],
  mixins: [ toasts ],

  components: {
    MemberPasswordUpdate,
    RightSaveButton
  },

  watch: {

    member: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_member = { ...this.member };
      }
    },
    'local_member.region_code'() {
      if (this.local_member.region_code in this.regions) {
        this.set_communes(this.regions[this.local_member.region_code].communes);
      }
    },

  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'companies', 'periods', 'profile', 'settings','isTHS']),
    secret_type() {
      return this.local_member.secret ? 'password' : 'text';
    },
    show_invoice_address() {
      return (
        this.unlocked_field('invoice_firstname') ||
        this.unlocked_field('invoice_lastname') ||
        this.unlocked_field('invoice_address') ||
        this.unlocked_field('invoice_co') ||
        this.unlocked_field('invoice_zipcode') ||
        this.unlocked_field('invoice_post') ||
        this.unlocked_field('invoice_country') ||
        this.unlocked_field('invoice_email') ||
        this.unlocked_field('invoice_phone')
      )
    },
    creating() {
      return !(this.local_member && this.local_member.member_id);
    }
  },

  methods: {

    submit_clicked() {
      if (this.creating) {
        this.$emit('create', this.local_member);
      }
      else {
        this.$emit('update', this.local_member);
      }

      this.$refs['save-button'].stop();
    },

    unlocked_field(name) {
      const obj = this.unlocks.find(item => item.name === name);

      return obj !== undefined && obj.value;
    },

    open_password_modal() {
      this.$refs['passwordModal'].show();
    },

    async change_data() {

      this.$emit('change', this.local_member);

    },

    async load_regions() {

      try {
        const res = await axios.get('https://zip.memlist.se/api/v1/regions_communes');

        this.regions = res.data.regions;
        this.region_code_options = [];

        for (var k in this.regions) {
          this.region_code_options.push({ value: k, text: this.regions[k].region });
        }

        this.region_code_options.sort(function(a, b) {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });

        if (this.local_member.region_code in this.regions) {
          this.set_communes(this.regions[this.local_member.region_code].communes);
        }

      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SEARCH.UNABLE_LIST_REGIONS'));
      }
    },


    set_communes(communes) {
      this.commune_code_options = [];

      for (var k in communes) {
        this.commune_code_options.push({ value: k, text: communes[k] });
      }

      this.commune_code_options.sort(function(a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    },

  },

  mounted() {

    if (this.member) {
      this.local_member = { ...this.member }
    }

    this.load_regions();
  },

  data() {
    return {

      local_member: {},

      regions: {},

      region_code_options: [],

      commune_code_options: [],

    };
  }
};

</script>
