<template>
  <div class="container">

    <b-modal
      :title="creating ? $t('MEMBER_EDUCATION.CREATING') : $t('MEMBER_EDUCATION.EDITING')"
      ref="member_education_editor_modal"
      hide-footer
      >

      <!-- Edit form -->
      <MemberEducationEditor
        :item="selected_item"
        @created="item_created"
        @updated="item_updated"
        @close="$refs['member_education_editor_modal'].hide()"
      />


    </b-modal>


    <v-tabs background-color="white" color="accent-4" left>
      <v-tab>{{ $t('EDUCATION.EDUCATIONS') }}</v-tab>
      <v-tab>{{ $t('EDUCATION.LADOK_ROWS') }}</v-tab>

      <v-tab-item>

        <MemberEducationsTable
          class="mt-4"
          ref="member_education-table"
          :items="member_educations"
          :member="member"
          @select="select_item_clicked"
          @delete="delete_item_clicked"
          @create="create_item_clicked"
        />
      </v-tab-item>

      <v-tab-item>
        <MemberLadokFileTable
          :member="member"
        />
      </v-tab-item>
    </v-tabs>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import ColorPickerInput from '@/view/components/ColorPickerInput';

import MemberEducationsTable from '@/view/components/member_editor_v2/sections/member_education/MemberEducationsTable.vue';
import MemberEducationEditor from '@/view/components/member_editor_v2/sections/member_education/MemberEducationEditor.vue';
import MemberLadokFileTable from '@/view/components/member_editor_v2/sections/member_education/MemberLadokFileTable.vue';

import { mapGetters } from 'vuex';

export default {

  name: 'MemberEducations',

  props: ['member','items','company'],
  emits: ['save_data'],
  mixins: [ toasts ],

  components: {
    TipLabel,
    RequiredFieldsInfo,
    ColorPickerInput,
    MemberEducationsTable,
    MemberLadokFileTable,
    MemberEducationEditor
  },

  watch: {
    items: {
      deep: true,
      handler(val) {
        this.local_items = { ...val };
      }
    },
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {
    async save_data() {

      this.$emit('save_data', this.member);

    },

    item_created(item) {
      // add to top
      this.local_items.unshift(item);

      this.$refs['member_education-table'].refresh();
    },

    item_updated(item) {
      // update item in list
      const index = this.local_items.findIndex((i) => {
        return i.id === item.id;
      });

      this.local_items[index] = item;
    },

    async edit_row_clicked(item) {
      this.selected_item = item;
      this.creating = false;

      this.$nextTick(()=>{
        this.$refs['edit-form'].show();
      });
    },

    async delete_row_clicked(item) {

    },

    async on_submit() {},

    validate_state(field, state) {},

    delete_item_clicked(item) {
      delete_item(item);
    },

    async delete_item(item) {
      try {

        const res = await axios.delete(`/member_education/${item.id}`);

        if (res.status === 204) {

          this.$refs[`member_education-table`].refresh();

          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER_EDUCATION.DELETED'));
          return;
        }
      }
      catch (err) {
        console.error('delete_item()', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER_EDUCATION.UNABLE_DELETE'));
    },

    create_item_clicked() {
      this.selected_item = {
        id: null,
        period_id: this.currentPeriodId,
        source: 'ADMIN',
        member_id: this.member.member_id
      };

      this.$nextTick(()=>{
        this.$refs.member_education_editor_modal.show();
      });

    },

    educationSelectClicked(education) {

      this.$refs['selectEducationModal'].hide();

      axios
        .post(`/education/${education.id}/member/${this.member.member_id}`, { period_id: this.currentPeriodId })
        .then(res => {
          if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('EDUCATION.ADDED'));
            this.refresh();
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('EDUCATION.UNABLE_ADD'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('EDUCATION.UNABLE_ADD'));
        });
    },


    getTypeStr(type) {
      switch (type) {
        case 'NONE': return this.$t('EDUCATION.TYPES.NONE');
        case 'PROGRAM': return this.$t('EDUCATION.TYPES.PROGRAM');
        case 'COURSE': return this.$t('EDUCATION.TYPES.COURSE');
        case 'FREE_COURSE': return this.$t('EDUCATION.TYPES.FREE_COURSE');
      }

      return this.$t('EDUCATION.TYPES.UNKNOWN')
    },
  },

  mounted() {
    if (this.items) {
      this.local_items = { ...this.items };
    }
    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {

      creating: true,

      selected_item: {},

      fields: [
        {
          key: 'education.name',
          label: this.$t('EDUCATION.NAME'),
          tdClass: 'td-overflow'

        },
        {
          key: 'education.code',
          label: this.$t('EDUCATION.CODE')
        },
        {
          key: 'education.type',
          label: this.$t('EDUCATION.TYPE'),
          formatter: (_, __, item) => {
            return this.getTypeStr(item.education.type);
          },
        },
        {
          key: 'education.company.name',
          label: this.$t('EDUCATION.SECTION'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        },
        {
          key: 'period.name',
          label: this.$t('EDUCATION.PERIOD'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        },
        {
          key: 'empty',
          label: '',
          thClass: ''
        }
      ],

      show_form: true,
      local_items: [],
    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
